import React from 'react';

import Layout from '../components/Layout';

const AboutPage = () => (
  <Layout fullMenu>
    <article id="main">
      <header>
        <h2>About Glenn O'Grady</h2>
        <p>Business advisor, Non-Executive Director and Investor</p>
      </header>
      <section className="wrapper style5">
        <div className="inner">
          <p><b>Glenn brings over twenty years experience of building high performing teams and delivering organisational transformation through a mixture of strategy setting, modern software delivery techniques and mentoring teams to reach their full potential.</b></p>
          <hr />
          <p>
          Glenn’s expertise lies at the intersection between tech and business, which puts him in the rare position of being able to advise businesses as they react to an ever-evolving business landscape. Decision making, a clear strategy and leadership are key factors that determine which business will thrive and which will struggle.
          </p>
          <p>
          Glenn co-founded leading software development company GuideSmiths in 2014 and launched the Madrid office in 2016. Having built the business from scratch, GuideSmiths quickly thrived and in March 2021, Glenn and his co-founder, Felipe Polo, <a target="_blank" href="https://www.dcslsoftware.com/dcsl-software-completes-transformational-acquisition-of-guidesmiths-in-12m-deal-to-become-dcsl-guidesmiths/">sold to DCSL Software for the sum of £12.3m</a>, creating DCSL GuideSmiths.
          </p>
          <p>
          Glenn remains with DCSL GuideSmiths as a board advisor, supporting the strengthened business which doubled in size and turnover with the acquisition. Glenn is also a board advisor to a number of businesses in the recruitment, media and utilities industries.
          </p>
        </div>
      </section>
    </article>
  </Layout>
);

export default AboutPage;
